import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";



const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { site } = useStaticQuery(graphql`
    query SiteAuthorQuery {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);
  return (
    <footer className="bg-gray-800 text-sm sm:text-base uppercase text-gray-400 flex justify-center">
      <p className="py-14 mx-4">
        © {site.siteMetadata.author}, {currentYear}
      </p>
    </footer>
  );
};

Footer.defaultProps = {
  author: "Author Name",
};

Footer.propTypes = {
  author: PropTypes.string,
  note: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default Footer;
