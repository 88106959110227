/* eslint-disable no-unused-vars */
import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NAV_LIST } from '../api/constants';
import Logo from "./logo";
import { Bars3Icon } from '@heroicons/react/24/solid'
import scrollToElem from "../api/scrollToElem";


function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div
      className={`flex flex-wrap items-center justify-between py-3 px-4 mx-auto md:px-8 md:py-5 transition-colors duration-300 ${isExpanded ? "bg-brand" : ""
        }`}
    >
      <div className="my-2 md:my-0 flex flex-no-wrap items-center justify-between w-full md:w-auto">
        <Link to="/" role="button">
          <Logo />
        </Link>
        <button
          aria-label="hamburger menu"
          className="items-center block py-1 px-1 sm:px-3 sm:py-2 text-white md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <Bars3Icon className="block h-8 w-8" />
        </button>
      </div>
      <nav
        className={`uppercase relative overflow-hidden transition-all ease-in-out max-h-0 duration-300 md:max-h-full ${isExpanded ? "max-h-40 " : ""
          }`}
      >
        <div className="md:block md:w-full  text-gray-50">
          {NAV_LIST.map(({ title, route }) => {
            // handle differently if homepage
            if (typeof window !== 'undefined' && window.location.pathname === '/' && route.includes('#')) {
              const scrollElem = route.replace('/', '')
              return <span key={title} onClick={() => scrollToElem(scrollElem)} className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6 hover:underline visited:text-white">{title}</span>
            }

            return (
              <Link
                className="block mt-4  no-underline md:inline-block md:mt-0 md:ml-6 hover:underline visited:text-white"
                key={title}
                to={route}
              >
                {title}
              </Link>
            )
          })}
        </div>
      </nav>
    </div>
  );
}

const EnhancedHeader = (BaseComponent) => {
  const wrappedComponent = (props) => {
    const { onHomePg } = props;
    if (onHomePg) {
      const [showBg, setShowBg] = useState(false);
      useScrollPosition(
        ({ currPos }) => {
          if (currPos.y < -50) {
            setShowBg(true);
          } else {
            setShowBg(false);
          }
        },
        [showBg]
      );
      return (
        <header
          className={`w-full fixed z-10 transition-all duration-700 ${showBg ? "bg-brand shadow-lg" : ""
            }`}
        >
          <BaseComponent {...props} />
        </header>
      );
    }
    return (
      <header className={`shadow-lg w-full fixed z-10 transition-all bg-brand`}>
        <BaseComponent {...props} />
      </header>
    );
  };

  return wrappedComponent;
};

export default EnhancedHeader(Header);
