import PropTypes from "prop-types";
import React from "react";
import Header from "./header";
import Footer from "./footer";

function Layout({ children, onHomePg }) {

  // because the header is fixed at the top of the page, we need to offset the children
  const headerOffset = onHomePg ? "0" : "92px";

  return (
    <div className="bg-white flex flex-col min-h-screen font-sans text-gray-900">
      <Header onHomePg={onHomePg} />
      <main
        className="bg-gray-100 flex-1 w-full"
        style={{ marginTop: headerOffset }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
