export const DISPLAY_NAMES = {
    'DEV': 'Software',
}

export const NAV_LIST = [
    {
        route: `/#about`,
        title: `About`,
    },
    {
        route: `/software`,
        title: DISPLAY_NAMES.DEV,
    },
    {
        route: `/awards`,
        title: `Awards`,
    },
    {
        route: `/#contact`,
        title: `Contact`,
    },
];