import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

function SEO({ description, lang, meta, keywords, title }) {
  const { site, ogImg } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
      ogImg: file(relativePath: { eq: "og-image.jpeg" }) {
        childImageSharp {
          fixed(width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `);

  const ogImgSrc = site.siteMetadata.siteUrl + ogImg.childImageSharp.fixed.src;
  const metaDescription = description || site.siteMetadata.description;
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": site.siteMetadata.title,
    "url": site.siteMetadata.siteUrl,
    "description": metaDescription,
    "author": {
      "@type": "Person",
      "name": site.siteMetadata.author
    },
    "publisher": {
      "@type": "Organization",
      "name": site.siteMetadata.author,
      "logo": {
        "@type": "ImageObject",
        "url": ogImgSrc,
        "width": 1200,
        "height": 630
      }
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImgSrc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: ogImgSrc,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(meta)}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
